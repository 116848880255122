<template>
    <v-app>
        <v-app-bar 
            app
            color="primary"
            dark
            clipped-left
            elevation="0"
        >
            <div class="d-flex align-center white--text">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="../assets/logo.png"
                    transition="scale-transition"
                    width="40"
                />
                {{ schoolName}}
                
            </div>
            <v-spacer></v-spacer>
            <Logout/>
        </v-app-bar>

        <v-navigation-drawer
            :mini-variant.sync="mini_md"            
            app
            clipped
            color="primary"
            dark
            permanent
            width="200"
            class="d-md-block"
        >         
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="secondary--text font-weight-light">
                        Term Reports
                    </v-list-item-title>
                    <v-list-item-subtitle class="secondary--text font-weight-bold mt-2">
                        Administrator
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            
            <AdminNavigation/>            
        </v-navigation-drawer>       

        <v-main class="grey lighten-5">
            <admin-dashboard 
                v-if="menuOptions.dashboard"
                v-on:progress-type="setProgressType"
            ></admin-dashboard>

            <teacher-lessons 
                v-if="menuOptions.employees"
            ></teacher-lessons>

            <students 
                v-if="menuOptions.students"
            ></students>

            <subjects 
                v-if="menuOptions.studentSubjects"
            ></subjects>

            <post-term-reports 
                v-if="menuOptions.postReports"
            ></post-term-reports>

            <edit-view-term-reports 
                v-if="menuOptions.termReports"
            ></edit-view-term-reports>
            
            <enter-marks 
                v-if="menuOptions.enterMarks"
            ></enter-marks>

            <reports 
                v-if="menuOptions.reports"
            ></reports>
        </v-main>

        <v-overlay :value="progressOverlay">
            <v-card 
                light
                v-if="linearProgress"
                width="300"
                class="pa-4"
            >
                <v-card-title>Registering Students...</v-card-title>
                <v-progress-linear                    
                    v-model="progress"
                    color="primary"
                    background-color="secondary"
                    height="25"
                    :buffer-value="bufferValue"
                    stream
                >
                    <strong>{{ Math.ceil(progress) }} %</strong>
                </v-progress-linear>
            </v-card>

            <v-progress-circular
                v-else
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
        </v-overlay>

        <sweet-modal
            :icon="modalIcon"
            ref="modal"
            overlay-theme="dark"
            :title="modalTitle"
            v-on:close="modalClose"
        >
            {{ modalMessage }}
        </sweet-modal>

        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"                       
        >
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn                    
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import AdminNavigation from './AdminNavigation';
import AdminDashboard from './AdminDashboard';
import TeacherLessons from './AdminEmployees';
import Students from './AdminStudents';
import PostTermReports from './AdminPostTermReports';
import { SweetModal } from 'sweet-modal-vue';
import { mapGetters, mapMutations } from 'vuex';
import Subjects from './AdminSubjects';
import EnterMarks from './EnterMarks';
import EditViewTermReports from './EditViewTermReports.vue';
import Reports from './AdminReports';

export default {
    name: 'Admin',
    components: {
        Logout,
        AdminDashboard,
        TeacherLessons,        
        AdminNavigation,        
        SweetModal,
        Students,
        Subjects,
        EnterMarks,
        EditViewTermReports,
        PostTermReports,
        Reports
    },

    created () {
        this.initialize();
    },

    computed: {     
        ...mapGetters({
            getUser: 'termReports/getUser',
            schoolName: 'termReports/getSchoolName',
            lessons: 'admin/getLessons',        
            registerStudents: 'admin/getRegisterStudents',
            modal: 'admin/getModal',
            modalTitle: 'admin/getModalTitle',
            modalIcon: 'admin/getModalIcon',
            modalMessage: 'admin/getModalMessage',
            progressOverlay: 'admin/getProgressOverlay',
            menuOptions: 'admin/getMenuOptions',
            snackbar: 'admin/getSnackbar', 
            progress: 'admin/getTermRegistrationProgress',                  
        }),           
    },
    watch: {
        modal:{
            handler(value){
                console.log('opening modal..')
                if(value) this.openModal();
            }
        },
    },
    data: () => ({                
        dialog: false,
        mini_md: false,  
        linearProgress: false,
        progressInterval: null,
        progressComplete: false,
        bufferValue: 10,      
    }),
    methods: {
        ...mapMutations({
            setModal: 'admin/setModal',
            setProgressOverlay: 'admin/setProgressOverlay',
        }),

        openModal(){            
            this.$refs.modal.open();
        },

        modalClose(){
            this.setModal(false);
        },

        initialize(){
            this.setProgressOverlay(true);
        },
        
        setProgressType (type) {
            if(type == 'linear')
            this.linearProgress = true;
            else this.linearProgress = false;            
        },
    }
}
</script>