<template>
    <v-row
        justify="center"
    >
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="950"
                height="80vh"
            >
                <v-card-title
                    class="d-flex white--text  py-2 px-0 primary"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-light text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header
                            v-on:next-lesson="closeMarkSheet"
                        />
                        <v-divider></v-divider>
                        <MarkSheet/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    :absolute="absolute"
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <Lesson/>
                    <lesson-admin></lesson-admin> 
                        
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="loading"
                        color="blue"
                    ></v-progress-circular> 
                </v-overlay>
               
            </v-card>
        </v-col>
       
    </v-row>    
</template>

<script>
import Header from './EnterMarksHeader'
import Lesson from './EnterMarksLesson'
import MarkSheet from './EnterMarksSheet'
import LessonAdmin from './EnterMarksLessonAdmin'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'EnterMarks',
    components: {
        Header,
        Lesson,
        LessonAdmin,
        MarkSheet,
    },

    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            overlay: 'termReports/getMarkSheetOverlay',
            loading: 'termReports/getMarkSheetLoading',
            totalStudents: 'termReports/getSubjectStudentsRegistered',  
            lessonSelected: 'termReports/getLessonSelected',
            progressText: 'termReports/getProgressText',
            sheet: 'termReports/getCommentSheetEnterMarks',
            // index: 'termReports/getSelectedTable2RecordIndex',
            table2Records: 'termReports/getTable2Records',  
        }),
    },

    data: () => ({
        absolute: true,             
        userTimetable: [],
    }),

    

    methods: {
        ...mapMutations({
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            setOverlay: 'termReports/setMarkSheetOverlay',
            setLoading: 'termReports/setMarkSheetLoading',
            setExpand: 'termReports/setExpandLessonOptions',
            setTable2Records: 'termReports/setTable2Records',
            setStudentName: 'termReports/setMarkSheetStudentSelected',
        }),

        initialize () {
            console.log("initializing enter marks")
            this.setExpand(false);
            this.$nextTick(() => {
                this.setOverlay(true);
                this.setLoading(true);
            })
        },

        setLessonSelected(e){
            this.loading = true;
            console.log(e);
        },
       
        closeMarkSheet(){
            this.setLoading(true);
            this.setOverlay(true);
            this.setTable2Records([]);
            this.setStudentName('');
        }
    }
}
</script>