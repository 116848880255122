<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"                        
        >    
            
            <v-toolbar
                color="primary"
                dark
            >
                <v-toolbar-title>Select Subject Class</v-toolbar-title>
            </v-toolbar>
            <v-list 
                light                
            >
                <v-list-item-group v-model="lessonSelected">
                    <v-list-item
                        v-for="(item, i) in lessons"
                        :key="i"
                        @click="setLesson(item)"
                    >
                        <v-list-item-avatar>
                            <v-icon class="primary white--text">
                                mdi-account-group
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.lesson"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-expand-transition>         
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {    
    mounted: function(){
        if(!this.admin)       
        this.getLessons();
    },

    data: () => ({
        lessons: [],
        lessonSelected: {lesson: '', className: '', subjectCode: '', subjectTitle: ''},
        open: true,
        clear: true,       
        focus: false,
        records: [],        
    }),
    
    computed: {
        ...mapGetters({            
            id: 'auth/getEmployeeId',
            lessonRecords: 'termReports/getLessons',
            expand: 'termReports/getExpandLessonOptions',
            studentsTotal: 'termReports/getSubjectStudentsTotal',
            studentsRegistered: 'termReports/getSubjectStudentsRegistered',
            studentsMarksEntered: 'termReports/getSubjectStudentsMarksEntered',                            
            admin: 'auth/getAdmin',
        }),
       
    },
    methods: {
        ...mapActions({
           getLessonRecords: 'termReports/getLessons',
           getStudents: 'termReports/getStudents',           
        }),
        ...mapMutations({
            setLessonSelected: 'termReports/setLessonSelected',
            setExpand: 'termReports/setExpandLessonOptions',
            markSheetLoading: 'termReports/setMarkSheetLoading',
            markSheetOverlay: 'termReports/setMarkSheetOverlay',
        }),

        async setLesson(lesson){
            this.setLessonSelected(lesson);
            this.setExpand(false);
            setTimeout(() => {
                this.markSheetLoading(true);
            }, 700)
            await this.getStudents();                      
        },

        async getLessons(){
            //console.log(this.records);
            await this.getLessonRecords(this.id);            
            this.markSheetLoading(false);
            this.setExpand(true);
            //console.log(this.lessonRecords);            
            this.lessonRecords.forEach(lessonRecord =>{
                let subject = lessonRecord.subject.title;
                this.lessons.push({
                    lesson: lessonRecord.form_class_id + ' ' + subject,
                    className: lessonRecord.form_class_id,                    
                    subjectCode: lessonRecord.subject.id,
                    subjectTitle: subject,
                    formLevel: lessonRecord.form_class.form_level,
                })
                this.focus = true;
            });            
        },

    }
}
</script>