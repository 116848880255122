<template>
    <v-list
        dense
        nav
    >
        <v-list-item-group
            v-model="selectedItem"
            color="white"
        > 
           <v-list-item
                v-for="(item, i) in items"
                :key="i"                                      
                @click="display(item)"                                    
            >
                <v-list-item-icon v-if="item.visible">
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content v-if="item.visible">
                    <v-list-item-title 
                        v-text="item.text"                        
                    >
                    </v-list-item-title>
                </v-list-item-content>

            </v-list-item>

        </v-list-item-group>                
    </v-list>                 
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    name: 'AdminNavigation',

    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            menuOptions: 'admin/getMenuOptions',
        })
    },

    data: () => ({        
        items: [
            { text: 'Dashboard', icon: 'mdi-view-dashboard', submenu: false, menu: 'dashboard', visible: true },            
           { text: 'Teachers', icon: 'mdi-account-group', submenu: false, menu: 'employees', visible: true },            
            { text: 'Students', icon: 'mdi-account-group', submenu: false, menu: 'students', visible: true },            
            { text: 'Subjects', icon: 'mdi-library', submenu: false, menu: 'studentSubjects', visible: true },            
            { text: 'Post Term Reports', icon: 'mdi-post', submenu: false, menu: 'postReports', visible: true },            
            { text: 'Edit\\View Term Details', icon: 'mdi-file-document-edit-outline', submenu: false, menu: 'termReports', visible: true },
            { text: 'Enter Marks', icon: 'mdi-keyboard', submenu: false, menu: 'enterMarks', visible: true },
            { text: 'Reports', icon: 'mdi-file-cabinet', submenu: true, menu: 'reports', visible: true},                      
        ],        
        selectedItem: 0,       
    }),
    
    methods: {
        ...mapActions({
            registrationData: 'admin/downloadRegistrationData',
            currentPeriod: 'termReports/getCurrentAcademicPeriod', 
        }),

        ...mapMutations({
            setMenuOptions: 'admin/setMenuOptions',
        }),

        async initialize () {
            let menuOptions = {};
            try {
                await this.currentPeriod();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            this.items.forEach( value => {
                if(value.menu != 'dashboard')
                menuOptions[value.menu] = false;
                else menuOptions[value.menu] = true;
            })
            this.setMenuOptions(menuOptions);            
        },

        display(item){
            Object.keys(this.menuOptions).forEach(key => {
                this.menuOptions[key] = false;
            })
            this.menuOptions[item.menu] = true;
        },
       
    }
}
</script>

<style scoped>
    ::v-deep .v-list-item__title{
        white-space: pre-wrap;
    }
</style>