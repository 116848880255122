<template>
    <v-card
        class="elevation-4 mx-auto"
        width="650"
    >
        <v-card-title
            class="py-2 pl-8 caption white--text font-weight-light text-uppercase primary"
        >
            <v-spacer></v-spacer>
                <span>End of Term Reports</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-row class="pa-4">
            <v-col>
                <v-list>
                    <v-list-item-group v-model="menu">
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="setMenu(item)"
                        >
                            <v-list-item-content>
                                <v-list-item-title 
                                    v-text="item.text"
                                    class="font-weight-normal text-uppercase primary--text"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-col>
                    <v-img
                    src="../assets/logo.png"
                    max-width="120"
                    contain
                    class="d-flex"
                ></v-img> 
            </v-col>
        </v-row>
    </v-card>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    name: 'MainMenu',
    data: () => ({
        items: [
            {
                icon: 'mdi-keyboard',
                text: 'Enter Marks (Subject Teachers)',
                menu: 'enter-marks'
            },
            {
                icon: 'mdi-pencil',
                text: 'Edit/View Term Reports (Form Teachers)',
                menu: 'edit-view-term-reports'
            }
        ],
        menu: '',  
    }),
    computed: {
        ...mapGetters({
            user: 'auth/getEmployeeSignedIn',
            getMarkSheetLoading: 'termReports/getMarkSheetLoading',
            getEditViewTermReportsOverlay: 'termReports/getEditViewTermReportsOverlay',            
            id: 'auth/getEmployeeId',
        }),
       
    },
   
    methods: {
        ...mapMutations({
            setMainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            setWelcomeMessage: 'termReports/setWelcomeMessage',
            setMainMenuButton: 'termReports/setMainMenuButton',
        }),
        ...mapActions({
            getFormClasses: 'termReports/getFormClasses',
            getFormTeacherClass: 'termReports/getAssignedFormTeacherClass',  
        }),

        setMenu({ menu }){
            this.setWelcomeMessage(false);
            this.setMainMenu(false);
            this.setMainMenuButton(true);
            switch(menu){               
                case 'enter-marks':
                    this.enterMarks(true);
                    this.editViewTermReports(false);                                       
                    break;
                case 'edit-view-term-reports':
                    this.enterMarks(false);
                    this.editViewTermReports(true);
                    break;    
            }
        },        
    }
}
</script>