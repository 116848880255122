<template>
    <div>
        <v-expand-transition>                            
            <v-card                
                max-width="300"
                class="mx-auto"
                transition="scroll-y-transition"
                v-show="expand"
                light 
            >
                
                <v-toolbar
                    color="primary"
                    dark
                >
                    <v-toolbar-title>
                        <v-btn
                            v-if="previous"
                            icon
                            @click="previousOption"
                        >
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        {{ toolbarTitle }}
                    </v-toolbar-title>
                </v-toolbar>
                <div style="max-height: 400px; max-width: 350px; overflow-y: auto">
                    <v-window v-model="step">
                        <v-window-item :value="1">    
                            <v-list 
                                light                    
                            >
                                <v-list-group
                                    v-for="item in formClasses"
                                    :key = "item.title"
                                    v-model="item.active"
                                    :prepend-icon="item.action"
                                    no-action
                                >
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <v-list-item
                                        v-for="subItem in item.items"
                                        :key="subItem.title"
                                        @click="setSelectedClass(subItem)"                                       
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="subItem.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-window-item>
                            <v-window-item :value="2">                        
                            <v-data-table                        
                                :headers="headers"
                                :items="subjects"
                                :search="search"
                                hide-default-footer
                                fixed-header
                                :items-per-page="itemsPerPage"
                                height="336px"
                                @click:row="displayMarkSheet"
                                dense
                            >
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search Subject"
                                            single-line
                                            hide-details
                                            clearable
                                        ></v-text-field>
                                    </v-toolbar>
                                </template>
                            </v-data-table>
                    
                        </v-window-item>
                    </v-window>                        
                </div>      
            </v-card>
        </v-expand-transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data: () => ({
        expand: false,        
        selectedFormClass: '',
        selectFormClass: false,
        selectSubject: false,
        search: '',
        headers: [
            {text: 'Subj Code', value: 'id', width: '80'},
            {text: 'Subject', value: 'title'},
        ],
        subjects: [],
        itemsPerPage: -1,
        step: 1,
        toolbarTitle: null,
        previous: false,
    }),

    mounted: function () {
        if(this.admin) this.initialize();
    },

    // watch: {
    //     formClasses: {
    //         handler () {
    //             console.log("form class changed");
    //             if(this.admin) this.initialize();
    //         }
    //     },

       
    // },

    computed: {
        ...mapGetters({
            formClasses: 'termReports/getFormClasses',
            admin: 'auth/getAdmin',
        })
    },

    methods: {
        ...mapMutations({           
            setLessonSelected: 'termReports/setLessonSelected',
            setMarkSheetLoading: 'termReports/setMarkSheetLoading',
            setFormClassesList: 'termReports/setFormClassesList',
            setFormClasses: 'termReports/setFormClasses',
        }),

        ...mapActions({
            getSubjects: 'admin/getSubjects',
            getStudents: 'termReports/getStudents',
            getFormClasses: 'termReports/getFormClasses',
            getCodedComments: 'termReports/getCodedComments', 
        }),

        setSelectedClass (val) {
            console.log(val);
            this.selectedFormClass = val;
            this.toolbarTitle = "Select Subject";
            this.previous = true;
            this.step = 2;
        },

        async initialize () {            
            try {
                const { data } = await this.getFormClasses();
                this.mapFormClasses(data);
                const { data: dataSubjects } = await this.getSubjects();
                this.subjects = dataSubjects;
                this.setMarkSheetLoading(false);
                this.$nextTick(() => {
                    this.toolbarTitle = "Select Class"
                    this.expand = true;                    
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async displayMarkSheet (val) 
        {
            console.log(val);
            this.setLessonSelected({
                className: this.selectedFormClass.title,
                subjectCode: val.id,
                subjectTitle: val.title,
                formLevel: this.selectedFormClass.formLevel,
            });
            this.expand = false;            
            setTimeout(() => {
                this.setMarkSheetLoading(true);
            }, 700)            
            await this.getStudents(this.id);   
        },

        previousOption () {
            this.step = 1; 
            this.previous = false
        },

        async mapFormClasses (data) 
        {
            //this.setFormClassesList(data);
            let formClasses = [];
            let form1Classes = [];
            let form2Classes = [];
            let form3Classes = [];
            let form4Classes = [];
            let form5Classes = [];
            let form6Classes = [];            
            data.forEach(record => {
                switch(record.form_level){
                    case 1:
                    form1Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 2:
                    form2Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 3:
                    form3Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 4:
                    form4Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 5:
                    form5Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 6:
                    form6Classes.push({
                        title: record.class_id
                    });
                    break;
                }                
            });
            formClasses.push({
                title: 'Form 1',
                action: 'mdi-school',                
                items: form1Classes
            });
            formClasses.push({
                title: 'Form 2',
                action: 'mdi-school',                
                items: form2Classes
            });
            formClasses.push({
                title: 'Form 3',
                action: 'mdi-school',                
                items: form3Classes
            });
            formClasses.push({
                title: 'Form 4',
                action: 'mdi-school',                
                items: form4Classes
            });
            formClasses.push({
                title: 'Form 5',
                action: 'mdi-school',                
                items: form5Classes
            });
            formClasses.push({
                title: 'Form 6',
                action: 'mdi-school',                
                items: form6Classes
            });            
            this.setFormClasses(formClasses);
            
            await this.getCodedComments();
        },
    }
}
</script>

<style scoped>
    ::v-deep tr {
        cursor: pointer;
    }
</style>