<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'auth/setOverlay',
            setStep: 'auth/setStep',
            setExpand: 'auth/setExpand',            
        }),

        async logOut () {            
            this.setOverlay(true);
            this.setStep(1);
            try {
                await this.signOut();
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }            
            
            this.setExpand(true);
            this.setOverlay(false);
            //console.log("logout complete");
            this.$router.replace('/')
        }

        
    }
}
</script>